<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>查询员工</h5>
				<div class="formgroup-inline">
					<span class="p-float-label mr-3">
						<Dropdown id="dropdown" :options="options" v-model="screen.role_id" dataKey="id"
							optionLabel="role_name" optionValue="id" :showClear="true"></Dropdown>
						<label for="dropdown">所属岗位</label>
					</span>
					<div class="field">
						<label for="phone" class="p-sr-only">Lastname</label>
						<InputText id="phone" v-model="screen.code" type="text" placeholder="电话" />
					</div>
					<Button label="查询" @click="goSearch"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="employeeList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']"
					@rowSelect="onRowSelect" @rowUnselect="onRowUnselect" selectionMode="single">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
								<Button label="新增员工" icon="pi pi-plus" class="mr-2"
									@click="openAddEmployee('add', '')" />
							</div>
						</div>
						<Dialog :header="lodtitle" v-model:visible="displayAddEmployee" :style="{ width: '30vw' }"
							:modal="true">
							<div class="field p-fluid">
								<label for="postName">所属岗位</label>
								<Dropdown id="postName" :options="options" v-model="lodarr.role_id" dataKey="id"
									optionLabel="role_name" optionValue="id"></Dropdown>
							</div>
							<div class="field p-fluid">
								<label for="employeeName">姓名</label>
								<InputText id="employeeName" type="text" class="p-error" v-model="lodarr.name"
									aria-describedby="employeeName-help" />
								<small id="employeeName-help" class="p-error"
									v-show="addEmployeeNameError">请输入正确的姓名，由中文或者英文组成</small>
							</div>
							<div class="field p-fluid">
								<label for="employeePhone">电话</label>
								<InputText id="employeePhone" type="text" class="p-error" v-model="lodarr.phone"
									aria-describedby="employeePhone-help" />
								<small id="employeePhone-help" class="p-error"
									v-show="addEmployeePhoneError">请输入正确的电话</small>
							</div>
							<div class="field p-fluid">
								<label for="employeePwd">密码</label>
								<Password id="employeePwd" class="p-error" aria-describedby="employeePwd-help"
									v-model="lodarr.password" toggleMask></Password>
								<small id="employeePwd-help" class="p-error"
									v-show="addEmployeePwdError">请输入超过6位密码</small>
							</div>

							<template #footer>
								<Button label="No" icon="pi pi-times" @click="displayAddEmployee = false"
									class="p-button-text" />
								<Button label="Yes" icon="pi pi-check" @click="doAddEmployee" autofocus />
							</template>
						</Dialog>
					</template>
					<template #empty>
						No customers found.
					</template>
					<template #loading>
						Loading customers data. Please wait.
					</template>
					<Column field="name" header="ID" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.id }}
						</template>
					</Column>
					<Column field="name" header="姓名" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.username }}
						</template>
					</Column>
					<Column field="name" header="岗位" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.role_name }}
						</template>
					</Column>
					<Column field="name" header="联系电话" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.phone }}
						</template>
					</Column>
					<Column field="name" header="状态" style="min-width:12rem">
						<template #body="{ data }">
							<InputSwitch v-model="data.status" />
						</template>
					</Column>
					<Column header="操作" style="min-width:10rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button label="编辑" class="p-button-success p-button-text mr-1"
									@click="openAddEmployee('edit', data)" />
								<Button  label="删除" class="p-button-danger p-button-text mr-1"
									@click="delEmployee(data)" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';
export default {
	data() {
		return {
			customer1: null,
			customer2: null,
			customer3: null,
			displayAddEmployee: false,
			filters1: null,
			filters2: {},
			loading1: true,
			loading2: true,
			idFrozen: false,
			products: null,
			expandedRows: [],
			statuses: [
				'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
			],
			representatives: [
				{ name: "Amy Elsner", image: 'amyelsner.png' },
				{ name: "Anna Fali", image: 'annafali.png' },
				{ name: "Asiya Javayant", image: 'asiyajavayant.png' },
				{ name: "Bernardo Dominic", image: 'bernardodominic.png' },
				{ name: "Elwin Sharvill", image: 'elwinsharvill.png' },
				{ name: "Ioni Bowcher", image: 'ionibowcher.png' },
				{ name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
				{ name: "Onyama Limba", image: 'onyamalimba.png' },
				{ name: "Stephen Shaw", image: 'stephenshaw.png' },
				{ name: "XuXue Feng", image: 'xuxuefeng.png' }
			],
			breadcrumbHome: { icon: 'pi pi-home', label: ' James Butt', to: '#' },
			breadcrumbItems: [
				{ label: '' }
			],

			//---------------------------------------------------
			screen: {
				code: '',
				role_id: ''
			},
			totalCount: 0, // 数据总数
			employeeList: [],

			// 新增数据
			lodarr: {
				id: "",
				role_id: "", // 岗位id
				enterprise_id: "", // 企业id
				enterprise_partition_id: "", // 场地id
				region_id: "", // 区域id
				position_id: "", // 位置id
				tung_id: "", // 栋舍
				name: "",
				phone: "",
				password: "",
				types: false,
				bath_code: "",
				gender: "",
				height: "",
				bath_type: "",
				archive_check: "",
			},

			options: [], // 岗位

			//---------------------------------------------------
		}
	},
	customerService: null,
	productService: null,
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();
	},
	mounted() {
		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;

		this.getRoleList();
		this.getEmployeeList();
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},
		// 搜索
		goSearch() {
			this.page_index = 1;
			this.getEmployeeList();
		},
		// 获取全部岗位
		getRoleList() {
			this.$http("/api/admin/role/indexx", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? ""
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
			}).then((res) => {
				if (res.code == 200) {
					this.options = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		getEmployeeList() {
			var ent_id = JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id;
			console.log('ent_id: ' + ent_id);

			this.$http('/api/admin/user/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				code: this.screen.code,
				role_id: this.screen.role_id
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					res.data.data.map(e => {
						e.status = e.status == 0
					})
					this.employeeList = res.data.data
					this.totalCount = res.data.total
					this.page_size = res.data.per_page
				} else {
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: 'Name: ' + res.msg, life: 3000 });
				}
			})
		},
		openAddEmployee(type, item) {
			if (type == "add") {
				this.lodarr = {
					id: "",
					role_id: "", // 岗位id
					enterprise_id: "", // 企业id
					enterprise_partition_id: "", // 场地id
					region_id: "", // 区域id
					position_id: "", // 位置id
					tung_id: "", // 栋舍
					name: "",
					phone: "",
					password: "",
					types: false,
					bath_code: "",
					gender: "",
					height: "",
					bath_type: "",
					archive_check: "",
				};
				this.lodtitle = "新增员工";
			} else {
				this.lodarr = {
					id: item.id,
					phone: item.phone,
					role_id: item.role_id,
					name: item.username,
					password: item.password,
					types: item.types == 1,
					bath_code: item.bath_code,
					gender: item.sex,
					height: item.height,
					bath_type: item.bath_type,
					archive_check: item.archive_check,
					enterprise_id: item.enterprise_id,
					enterprise_partition_id: item.enterprise_partition_id,
					region_id: item.region_id,
					position_id: item.position_id,
					tung_id: item.tung_id,
				};
				// this.getFieldList(item.enterprise_id);
				// this.getRegionList(item.enterprise_partition_id);
				// this.getPositionList(item.region_id);
				// this.getTungList(item.position_id);

				this.lodtitle = "编辑员工";
			}

			this.displayAddEmployee = true;
		},
		doAddEmployee() {
			this.$http("/api/admin/user/create", {
				username: this.lodarr.name,
				password: this.lodarr.password,
				phone: this.lodarr.phone,
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? this.lodarr.enterprise_id
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				enterprise_ids: this.lodarr.enterprise_id,
				role_id: this.lodarr.role_id,
				types: this.lodarr.types ? 1 : 0,
				bath_code: this.lodarr.bath_code,
				height: this.lodarr.height,
				bath_type: this.lodarr.bath_type,
				archive_check: this.lodarr.archive_check,
				enterprise_partition_id: this.lodarr.enterprise_partition_id,
				region_id: this.lodarr.region_id,
				position_id: this.lodarr.position_id,
				tung_id: this.lodarr.tung_id,
				sex: this.lodarr.gender,
				id: this.lodarr.id,
			}).then((res) => {
				if (res.code == 200) {
					//this.$message.success(res.msg);
					this.getEmployeeList();
				} else {
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.data, life: 3000 });
				}

				this.displayAddEmployee = false;
			});
		},

		delEmployee(item) {
			this.$confirm.require({
				message: '确定要删除这个员工吗？',
				header: '提示',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http("/api/admin/user/del", {
						id: item.id,
						enterprise_id:
							JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
								? ""
								: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
					}).then((res) => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功' });
							this.getEmployeeList();
						} else {
							this.$toast.add({ severity: 'error', summary: res.msg });
						}
					});
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},








		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},

		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>